import React from 'react'
import LayoutWithHeaderAndFooter from '../components/layouts/LayoutWithHeaderAndFooter'
import { DisplayLarge } from '../components/typography'
import SEO from '../components/seo'
import QuestionAndAnswer from '../components/QuestionAndAnswer'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../constants/i18n'

const FAQ = (): JSX.Element => {
  const { t } = useTranslation(Namespace.FAQ)

  const contents = t('contents', { returnObjects: true }) as Array<{
    question: string
    answer: string
  }>

  return (
    <LayoutWithHeaderAndFooter heading={t('title')}>
      <SEO title="FAQ" />
      <div className="flex flex-col mx-auto max-w-screen-md">
        <DisplayLarge as="h2" className="mb-12">
          {t('subtitle')}
        </DisplayLarge>
        <div className="grid grid-cols-1 gap-2 md:gap-4">
          {Array.isArray(contents) &&
            contents.map(({ question, answer }, index) => (
              <QuestionAndAnswer topic={question} key={question}>
                <Trans
                  t={t}
                  i18nKey={`contents.${index}.answer`}
                  components={{
                    a: (
                      <a
                        className="text-blue-600 underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    ),
                  }}
                >
                  {answer}
                </Trans>
              </QuestionAndAnswer>
            ))}
        </div>
      </div>
    </LayoutWithHeaderAndFooter>
  )
}

export default FAQ
