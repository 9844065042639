import React from 'react'

interface QuestionAndAnswerProps {
  topic?: string
  children?: Array<string | JSX.Element> | string | JSX.Element
}

const QuestionAndAnswer = ({
  topic,
  children,
}: QuestionAndAnswerProps): JSX.Element => (
  <div className="flex flex-col">
    <div className="bg-gray-200 py-4 px-6 -mx-6 md:-mx-12 md:px-12 rounded-t-sm">
      <p className="font-bold">{topic}</p>
    </div>
    <div className="bg-gray-100 py-4 px-6 -mx-6 md:-mx-12 md:px-12 md:py-8">
      {children}
    </div>
  </div>
)

export default QuestionAndAnswer
